




import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { newStatusFnc } from '../../../plugins/helper';

@Component
export default class LabelNew extends Vue {
  @Prop({default: null})
  private date!: Date;

  get newStatus(): boolean {
    if (!this.date) {
      return false;
    }
    return newStatusFnc(this.date);
  }
}
