import Vue from 'vue';
import VueRouter, { Route, RouteConfig, NavigationGuardNext } from 'vue-router';
import edit from './edit';
import mail from './mail';
import reset from './reset';
import cancel from './cancel';
import course from './course';

Vue.use(VueRouter);

export const AccountEdit = edit;
export const AccountMail = mail;
export const AccountCancel = cancel;
export const AccountCourse = course;
export const AccountReset = reset;

export default {
  AccountEdit,
  AccountMail,
  AccountCancel,
  AccountCourse,
  AccountReset,
};
