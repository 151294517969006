import Vue from 'vue';
import VueRouter, { Route, RouteConfig, NavigationGuardNext } from 'vue-router';
import axios, {AxiosPromise, AxiosResponse, AxiosError} from 'axios';
import { WPPost, CourseResponseCourses, CourseResponse } from '../../../types/typescript-axios/api';
Vue.use(VueRouter);

export const courseForm = {
  path: '/',
  component: () => import(/* webpackChunkName: "simpleLayouts" */ '../../layouts/SimpleLayouts.vue'),
  meta: { requiresAuth: true },
  children: [
    {
      path: '/course/:courceId/waiting/:key',
      component: () => import(/* webpackChunkName: "CourseDetailWaiting" */ '../../views/course/detail/Waiting.vue'),
      children: [
        {
          path: '',
          name: 'CourseWaitingForm',
          component: () => import(/* webpackChunkName: "form" */ '../../views/course/detail/waiting/index.vue'),
        },
        {
          path: 'error',
          name: 'CourseWaitingFormError',
          component: () => import(/* webpackChunkName: "FormError" */ '../../views/NotFound.vue'),
          meta: { title: 'Error 講座' },
        },
        {
          path: 'complete',
          name: 'CourseWaitingComplete',
          component: () => import(/* webpackChunkName: "complete" */ '../../views/course/detail/waiting/Complete.vue'),
        },
      ],
    },
  ],
};

export default courseForm;
