





























import { Component, Vue, Prop } from 'vue-property-decorator';
import { courseTimeStatus, mansekiStatus, isReservedStatus, isWaitingList, isCourseApplicationMethod } from '../../../plugins/helper';
import { CourseResponseCourses } from '../../../../types/typescript-axios/api';
@Component({
})
export default class LabelStatus extends Vue {
  @Prop({default: {}})
  private course!: CourseResponseCourses;

  private get isLogin() {
    return this.$store.getters['auth/isLogin'];
  }

  private get isReserveStatus(): number {
    const nowDate: Date = new Date();
    const courseStatus: number = courseTimeStatus(this.course);
    const isStatus1: number = isReservedStatus(this.course);
    const isManseki: boolean = mansekiStatus(this.course);
    const isCourseMethod: number = isCourseApplicationMethod(this.course);
    const isWaiting: boolean = isWaitingList(this.course);

    // 開催終了 終了
    if (courseStatus === 3) {
      return 1;
    }

    // 申込み中
    if (
      courseStatus < 3 &&
      (isStatus1 === 0 || isStatus1 === 2)
    ) {
      return 2; // 申込み中
    }

    if (courseStatus === 0) { // 受付前 予告
      return 0;
    }

    if (courseStatus === 2) { // 受付期間終了 受付終了
      return 3;
    }

    if (
      isManseki && // 満席
      courseStatus === 1 && // 申込み受付期間中
      isCourseMethod === 0 && // 先着順
      !isWaiting && // キャンセル待ち受付不可講座
      (isStatus1 === -1 || isStatus1 === 1) // 未申込み || キャンセル
    ) {
      return 4; // 満席
    }

    if (courseStatus < 2) { // 受付前 予告
      return 5; // 受付中
    }

    return -1;
  }
}
