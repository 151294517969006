import Vue from 'vue';
import Vuex from 'vuex';
import window from './window';
import auth from './auth';
import account from './account';
import course from './course';
import nonce from './nonce';
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    window,
    auth,
    account,
    course,
    nonce,
  },
});
