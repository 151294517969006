import Vue from 'vue';
import VueRouter, { Route, RouteConfig, NavigationGuardNext } from 'vue-router';

Vue.use(VueRouter);

export const forgetPassword = {
  path: '/',
  component: () => import(/* webpackChunkName: "simpleLayouts" */ '../../layouts/SimpleLayouts.vue'),
  children: [
    {
      path: '/login/forget_password',
      name: 'ForgetPassword',
      component: () => import(/* webpackChunkName: "forgetPassword" */ '../../views/login/forget_password/Index.vue'),
      meta: { requiresNoAuth: true, title: '会員情報入力 パスワードを忘れた方' },
    },
    {
      path: '/login/forget_password/complete',
      name: 'ForgetPasswordComplete',
      component: () => import(/* webpackChunkName: "forgetPasswordComplete" */ '../../views/login/forget_password/Complete.vue'),
      meta: { requiresNoAuth: true, title: '再設定メール送信 パスワードを忘れた方' },
    },
    {
      path: '/login/forget_password/error',
      name: 'forgetPasswordError',
      component: () => import(/* webpackChunkName: "forgetPasswordError" */ '../../views/login/forget_password/Error.vue'),
      meta: { requiresNoAuth: true, title: 'エラー パスワードを忘れた方' },
    },
  ],
};


export default forgetPassword;
