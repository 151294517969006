import Vue from 'vue';
import VueRouter, { Route, RouteConfig, NavigationGuardNext } from 'vue-router';

Vue.use(VueRouter);

export const forgetPasswordEdit = {
  path: '/',
  component: () => import(/* webpackChunkName: "simpleLayouts" */ '../../layouts/SimpleLayouts.vue'),
  children: [
    {
      path: '/login/forget_password/edit_password/:key',
      component: () => import(/* webpackChunkName: "EditPassword" */ '../../views/login/forget_password/EditPassword.vue'),
      meta: { requiresNoAuth: true },
      children: [
        {
          path: '',
          name: 'EditPasswordIndex',
          meta: { title: 'ご本人確認 パスワード再設定' },
          component: () => import(/* webpackChunkName: "EditPasswordIndex" */ '../../views/login/forget_password/edit_password/EditPassword.vue'),
        },
        {
          path: 'confirm',
          name: 'EditPasswordConfirm',
          component: () => import(/* webpackChunkName: "editPasswordConfirm" */ '../../views/login/forget_password/edit_password/Confirm.vue'),
          meta: { title: 'パスワード再設定 パスワード再設定' },
          beforeEnter: ((to: Route, from: Route, next: NavigationGuardNext) => {
            if (from.path !== `/login/forget_password/edit_password/${to.params.key}/`) {
              next({ path: `/login/forget_password/edit_password/${to.params.key}/` });
            } else {
              next();
            }
          }),
        },
        {
          path: 'error',
          name: 'EditPasswordError',
          component: () => import(/* webpackChunkName: "EditPasswordError" */ '../../views/login/forget_password/Error.vue'),
        },
      ],
    },
    {
      path: '/login/forget_password/edit_password/:key/complete',
      name: 'EditPasswordComplete',
      component: () => import(/* webpackChunkName: "editPasswordComplete" */ '../../views/login/forget_password/edit_password/Complete.vue'),
      meta: { title: 'パスワード再設定完了 パスワード再設定' },
      beforeEnter: ((to: Route, from: Route, next: NavigationGuardNext) => {
        if (from.path !== `/login/forget_password/edit_password/${to.params.key}/confirm/`) {
          next({ path: `/login/forget_password/edit_password/${to.params.key}/` });
        } else {
          next();
        }
      }),
    },
  ],
};


export default forgetPasswordEdit;
