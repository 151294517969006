






















import { Component, Vue } from 'vue-property-decorator';

@Component
export default class FooterLink extends Vue {
  get sitemapUrl(): string {
    return process.env.VUE_APP_PATH_SITEMAP;
  }
  get kiyakuUrl(): string {
    return process.env.VUE_APP_PATH_KIYAKU;
  }
  get privacypolicyUrl(): string {
    return process.env.VUE_APP_PATH_PRIVACY_POLICY;
  }
  get privacypolicyToriatsukaiUrl(): string {
    return process.env.VUE_APP_PATH_PRIVACY_POLICY_TORIATSUKAI;
  }
  get privacypolicyCookieUrl(): string {
    return process.env.VUE_APP_PATH_PRIVACY_POLICY_COOKIE;
  }
  get noticeUrl(): string {
    return process.env.VUE_APP_PATH_PRIVACY_NOTICE;
  }
  get noticeSuishoUrl(): string {
    return process.env.VUE_APP_PATH_PRIVACY_NOTICE_SUISHO;
  }
  get basicPersonalPolicyUrl(): string {
    return process.env.VUE_APP_PATH_BASIC_PERSONAL_POLICY;
  }
}
