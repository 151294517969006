


















import { Component, Vue } from 'vue-property-decorator';
import { WPPost, WPCategories } from '../../../../types/typescript-axios/api';
import axios, {AxiosResponse, AxiosError} from 'axios';
import VueRouter from 'vue-router';
import store from '../../../store';

@Component
export default class FooterFaq extends Vue {
  private maxLength: number = 5;
  private url: string = '/wp-json/wp/v2/pages';
  private catUrl: string = '/wp-json/wp/v2/faq_cat';
  private faqContents: WPPost[] = [];
  private faqCategory: WPCategories[] = [];
  private async mounted() {
    const categoryRes: any = await axios.get<AxiosResponse>(this.catUrl);
    this.faqCategory = categoryRes.data;
    const contentsData: any = await axios.get<AxiosResponse>(this.url, {
      params: {
        slug: 'footer_faq',
      },
    });
    if (contentsData.data.length > 0) {
      this.faqContents = contentsData.data[0].footer_faq;
    }
  }
  get faqUrl(): string {
    return process.env.VUE_APP_PATH_FAQ;
  }
}
