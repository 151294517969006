import { Commit } from 'vuex';
import axios, {AxiosResponse, AxiosError} from 'axios';
import {AccountRequest, PostLoginResponse} from '../../types/typescript-axios/api';
import { params } from 'vee-validate/dist/types/rules/alpha';
import { load } from 'recaptcha-v3';

export const state = {
};

export const getters = {
};

export const mutations = {
};

export const actions = {
  async courseListAction({ commit }: { commit: Commit }) {
    await axios.get<AxiosResponse>('/wp-json/moc/course')
    .then( (response) => {
      return {
        success: true,
      };
    })
    .catch( (e: AxiosError) => {
      console.log(e);
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
