// import { axios } from 'vue/types/umd';
import { Commit, Dispatch } from 'vuex';
import axios, {AxiosResponse, AxiosError} from 'axios';
import {LoginRequest, PostLoginResponse, GetLoginResponse} from '../../types/typescript-axios/api';
import { load } from 'recaptcha-v3';
import auth from '@/store/auth';

export const state = {
  isLogin: -1,
  loginDate: null,
  userId: '',
  token: '',
};

export const getters = {
  isLogin: (state: any) => state.isLogin,
  isUserId: (state: any) => state.userId,
};

export const mutations = {
  login: (state: any) => {
    state.isLogin = 1;
    state.loginDate = new Date();
  },
  logout: (state: any) => {
    state.isLogin = 0;
    state.loginDate = null;
  },
  setUserId: (state: any, val: string) => state.userId = val,
  setToken: (state: any, val: string) => state.token = val,
};

export const actions = {
  async loginAuth(
    { commit, dispatch }: { commit: Commit, dispatch: Dispatch },
    param: LoginRequest,
  ) {
    const $param: LoginRequest = param;
    const recaptcha = await load(process.env.VUE_APP_RECAPTCHA_KEY);
    const token = await recaptcha.execute('homepage');
    $param.recaptchaToken = token;
    const nonce = await dispatch('nonce/getData', null , {root: true});
    const query = new URLSearchParams(nonce);
    const res = await new Promise((resolve, reject) => {
      axios.post<LoginRequest, AxiosResponse<PostLoginResponse>>(
        '/wp-json/moc/auth?' + query.toString(),
        $param,
        { withCredentials: true },
      )
      .then( (response: AxiosResponse<PostLoginResponse>) => {
        commit('login');
        resolve(response);
      })
      .catch( (e: AxiosError) => {
        reject(e.response);
      });
    });
    return res;
  },
  async logoutAuth({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }) {
    const $param: any = {};
    const recaptcha = await load(process.env.VUE_APP_RECAPTCHA_KEY);
    const token = await recaptcha.execute('homepage');
    $param.recaptchaToken = token;
    $param.token = auth.state.token;
    const nonce = await dispatch('nonce/getData', null , {root: true});
    const query = new URLSearchParams(nonce);
    const res = await new Promise((resolve, reject) => {
      axios.delete<AxiosResponse>(
        '/wp-json/moc/auth?' + query.toString(),
        { data: $param, withCredentials: true },
      )
      .then( (response) => {
        commit('logout');
        resolve(response);
      })
      .catch( (e: AxiosError) => {
        reject(e.response);
      });
    });
    return res;
  },
  async loginStatus({ commit }: { commit: Commit }) {
    const res = await new Promise((resolve, reject) => {
      axios.get<GetLoginResponse>('/wp-json/moc/auth', { withCredentials: true })
      .then( (response) => {
        commit('setUserId', response.data.userid);
        resolve(response);
      })
      .catch( (e: AxiosError) => {
        reject(e.response);
      });
    });
    return res;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
