import Vue from 'vue';
import VueRouter, { Route, RouteConfig, NavigationGuardNext } from 'vue-router';

Vue.use(VueRouter);

export const reset = {
  path: '/',
  component: () => import(/* webpackChunkName: "simpleLayouts" */ '../../layouts/SimpleLayouts.vue'),
  children: [
    {
      path: '/account/reset',
      component: () => import(/* webpackChunkName: "editAccountReset" */ '../../views/account/Reset.vue'),
      meta: { requiresAuth: false },
      children: [
        {
          path: ':key',
          name: 'EditAccountMail',
          component: () => import(/* webpackChunkName: "editAccountResetEdit" */ '../../views/account/reset/Index.vue'),
          meta: { title: 'ご本人確認 メールアドレス変更' },
        },
        {
          path: ':key/complete',
          name: 'EditAccountMailComplete',
          component: () => import(/* webpackChunkName: "EditAccountMailComplete" */ '../../views/account/reset/Complete.vue'),
          meta: { title: '変更完了 メールアドレス変更' },
        },
      ],
    },
  ],
};

export default reset;
