















































import { Component, Vue, Emit } from 'vue-property-decorator';
import store from '../../store';
import GlobalNavi from '../contents/header/GlobalNavi.vue';
import MypageNavi from '../contents/header/MypageNavi.vue';
import HeaderLogo from '../contents/header/HeaderLogo.vue';

@Component({
  components: {
    GlobalNavi,
    MypageNavi,
    HeaderLogo,
  },
})
export default class Header extends Vue {
  /**
   * navBtnShow
   * true のとき表示される
   */
  public navBtnShow: boolean = false;
  // myPageBtnShow trueの時に表示される
  public myPageBtnShow: boolean = false;
  public elementsBody = document.getElementsByTagName('body');
  public elementsHtml = document.getElementsByTagName('html');
  public elementsNavList = document.getElementsByClassName('global-nav');
  public elementsMypageList = document.getElementsByClassName('global-header-mypage-nav-list');

  get isLogin() {
    return this.$store.getters['auth/isLogin'];
  }
  get overlayShow() {
    if (this.navBtnShow || this.myPageBtnShow) {
      return true;
    } else {
      return false;
    }
  }
  public navBtn() {
    this.navBtnShow = !this.navBtnShow;
    this.myPageBtnShow = false;
    if (this.navBtnShow) {
      this.scrollStop();
      Array.prototype.forEach.call(this.elementsNavList, (element) => {
        element.classList.add('nav-inner-scroll');
      });
    } else {
      this.scrollStopReset();
    }
  }
  public myPageBtn() {
    this.myPageBtnShow = !this.myPageBtnShow;
    this.navBtnShow = false;
    if (this.myPageBtnShow) {
      this.scrollStop();
      Array.prototype.forEach.call(this.elementsMypageList, (element) => {
        element.classList.add('nav-inner-scroll');
      });
    } else {
      this.scrollStopReset();
    }
  }
  public overlay() {
    this.myPageBtnShow = false;
    this.navBtnShow = false;
    this.scrollStopReset();
  }

  @Emit()
  private scrollStop() {
    Array.prototype.forEach.call(this.elementsBody, (element) => {
      element.classList.add('scroll-stop');
    });
    Array.prototype.forEach.call(this.elementsHtml, (element) => {
      element.classList.add('scroll-stop');
    });
  }
  @Emit()
  private scrollStopReset() {
    Array.prototype.forEach.call(this.elementsBody, (element) => {
      element.classList.remove('scroll-stop');
    });
    Array.prototype.forEach.call(this.elementsHtml, (element) => {
      element.classList.remove('scroll-stop');
    });
  }
  private destroyed() {
    this.scrollStopReset();
  }
}
