import Vue from 'vue';
import VueRouter, { Route, RouteConfig, NavigationGuardNext } from 'vue-router';

Vue.use(VueRouter);

export const cancel = {
  path: '/',
  component: () => import(/* webpackChunkName: "simpleLayouts" */ '../../layouts/SimpleLayouts.vue'),
  children: [
    {
      path: '/account/cancel',
      component: () => import(/* webpackChunkName: "cancelIndex" */ '../../views/account/Cancel.vue'),
      children: [
        {
          path: '',
          name: 'AccountCancel',
          component: () => import(/* webpackChunkName: "cancelIndex" */ '../../views/account/cancel/Index.vue'),
          meta: { requiresAuth: true, title: '退会確認 退会手続き' },
        },
        {
          path: 'error',
          name: 'AccountCancelError',
          component: () => import(/* webpackChunkName: "accountCancelError" */ '../../views/account/cancel/Error.vue'),
          meta: { title: 'Error 退会手続き' },
        },
        {
          path: 'complete',
          name: 'CancelComplete',
          component: () => import(/* webpackChunkName: "cancelComplete" */ '../../views/account/cancel/Complete.vue'),
          meta: { title: '退会完了 退会手続き' },
          beforeEnter: ((to: Route, from: Route, next: NavigationGuardNext) => {
            if (from.path === '/account/cancel/') {
              next();
            } else {
              next({ path: '/' });
            }
          }),
        },
      ],
    },
  ],
};

export default cancel;
