import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import VueAxios from 'vue-axios';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import VueGtm from 'vue-gtm';
import VueScrollTo from 'vue-scrollto';
import VueGtag from 'vue-gtag';
import './assets/scss/app.scss';

Vue.use(VueScrollTo);
Vue.config.productionTip = false;
Vue.use(VueAxios, axios);

Vue.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_RECAPTCHA_KEY,
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

Vue.use(VueGtm, {
  id: process.env.VUE_APP_GTM_KEY,
  debug: true,
});

Vue.use(VueGtag, {
  config: {
    id: process.env.VUE_APP_GA_ID,
    params: {
      send_page_view: false,
    },
  },
});
