import Vue from 'vue';
import VueRouter, { Route, RouteConfig, NavigationGuardNext } from 'vue-router';
import forgetPassword from './forgetPassword';
import forgetPasswordEdit from './forgetPasswordEdit';

Vue.use(VueRouter);

export const LoginIndex = {
  path: '/',
  component: () => import(/* webpackChunkName: "simpleLayouts" */ '../../layouts/SimpleLayouts.vue'),
  children: [
    {
      path: '/login',
      name: 'Login',
      component: () => import(/* webpackChunkName: "login" */ '../../views/login/Index.vue'),
      meta: { title: 'ログイン', requiresNoAuth: true },
    },
    {
      path: '/login/error',
      name: 'LoginError',
      component: () => import(/* webpackChunkName: "loginerror" */ '../../views/login/Error.vue'),
      meta: { title: 'Error ログイン' },
    },
  ],
};
export const LoginForgetPassword = forgetPassword;
export const LoginForgetPasswordEdit = forgetPasswordEdit;
export default {
  LoginIndex,
  LoginForgetPassword,
  LoginForgetPasswordEdit,
};
