

















import { Component, Vue, Emit, Prop, Watch } from 'vue-property-decorator';
import Faq from '../../../views/Faq.vue';

@Component({
})
export default class InfoBanner extends Vue {
  @Prop({default: ''})
  private banners!: string;

  @Emit()
  private isRouterLink(data: any): boolean {
    const str = data.acf.bannerlinkurl;
    if (data.acf.bannerlinkoption === '0' && String(str).indexOf(process.env.VUE_APP_MY_DOMAIN) === 0) {
      return true;
    } else {
      return false;
    }
  }

  @Emit()
  private routerLinkTo(str: string): string {
    const reg = process.env.VUE_APP_MY_DOMAIN;
    return str.replace(new RegExp(reg), '');
  }
}
