import Vue from 'vue';
import VueRouter, { Route, RouteConfig, NavigationGuardNext } from 'vue-router';
import store from '@/store';

Vue.use(VueRouter);

export const edit = {
  path: '/',
  component: () => import(/* webpackChunkName: "simpleLayouts" */ '../../layouts/SimpleLayouts.vue'),
  children: [
    {
      path: '/account/edit_account',
      component: () => import(/* webpackChunkName: "editAccountParent" */ '../../views/account/EditAccount.vue'),
      meta: { requiresAuth: true },
      children: [
        {
          path: '',
          name: 'EditAccountIndex',
          // tslint:disable-next-line:max-line-length
          component: () => import(/* webpackChunkName: "editAccount" */ '../../views/account/edit_account/Index.vue'),
          meta: { title: '情報変更 会員情報変更' },
          beforeEnter: ((to: Route, from: Route, next: NavigationGuardNext) => {
            store.commit('account/setEditStep', Date.now());
            // const date: number = Date.now();
            // sessionStorage.setItem('edit_account_start_date', String(date));
            next();
          }),
        },
        {
          path: 'confirm',
          name: 'EditAccountConfirm',
          component: () => import(/* webpackChunkName: "editAccountConfirm" */ '../../views/account/edit_account/Confirm.vue'),
          meta: { title: '確認 会員情報変更' },
          beforeEnter: ((to: Route, from: Route, next: NavigationGuardNext) => {
            const oldStepNum: number|null = store.getters['account/isEditStep'];
            if (oldStepNum === null) {
              next({ path: '/account/edit_account/' });
            } else {
              store.commit('account/setEditStep', 1);
              next();
            }
          }),
        },
        {
          path: 'complete',
          name: 'EditAccountComplete',
          // tslint:disable-next-line:max-line-length
          component: () => import(/* webpackChunkName: "editAccountComplete" */ '../../views/account/edit_account/Complete.vue'),
          meta: { title: '変更完了 会員情報変更' },
          beforeEnter: ((to: Route, from: Route, next: NavigationGuardNext) => {
            const oldStepNum: number|null = store.getters['account/isEditStep'];
            if (oldStepNum === null) {
              next({ path: '/account/edit_account/' });
            } else {
              store.commit('account/setEditStep', 2);
              next();
            }
          }),
        },
        {
          path: 'error',
          name: 'EditAccountError',
          component: () => import(/* webpackChunkName: "editAccountError" */ '../../views/account/edit_account/Error.vue'),
          meta: { title: 'Error 会員情報変更' },
          beforeEnter: ((to: Route, from: Route, next: NavigationGuardNext) => {
            const oldStepNum: number|null = store.getters['account/isEditStep'];
            if (oldStepNum === null) {
              next({ path: '/account/edit_account/' });
            } else {
              next();
            }
          }),
        },
      ],
    },
  ],
};

export default edit;
