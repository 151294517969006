import { Commit } from 'vuex';
export const sleep = (sec = 1000) => {
  return new Promise((resolve, reject) => {
      setTimeout(resolve, sec * 1000);
      // setTimeout(() => {
      //     reject(new Error( 'エラー' ) );
      // }, sec * 1000);
  });
};

export const state = {
  notFound: 200,
  title: '',
  description: '',
};

export const getters = {
  isNotFound(state: any) {
    return state.notFound;
  },
  isTitle(state: any) {
    return state.title;
  },
};

export const mutations = {
  setNotFound(state: any, val: number) {
    // 状態を変更する
    state.notFound = val;
  },
  setTitle(state: any, val: string) {
    state.title = val;
  },
  setDesc(state: any, val: string) {
    state.description = val;
    const descDom: any = document.querySelector('meta[name="description"]');
    if (typeof descDom !== 'undefined') {
      descDom.setAttribute('content', val);
    }
  },
};

export const actions = {
  async actionA({ commit }: { commit: Commit }) {
    try {
      await sleep(3); // ここで10秒間止まります
      // ここに目的の処理を書きます。
      commit('setNotFound', 200);
    } catch (err) {
      commit('setNotFound', 404);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
