import Vue from 'vue';
import VueRouter, { Route, RouteConfig, NavigationGuardNext } from 'vue-router';
import axios, {AxiosPromise, AxiosResponse, AxiosError} from 'axios';
import { WPPost, CourseResponseCourses, CourseResponse } from '../../../types/typescript-axios/api';
import { isReservedStatus, isVisibilityOrderBtn } from '../../plugins/helper';
Vue.use(VueRouter);

export const courseForm = {
  path: '/',
  component: () => import(/* webpackChunkName: "simpleLayouts" */ '../../layouts/SimpleLayouts.vue'),
  meta: { requiresAuth: true },
  children: [
    {
      path: '/course/:courceId',
      component: () => import(/* webpackChunkName: "CourseDetail" */ '../../views/course/Detail.vue'),
      beforeEnter: (async (to: Route, from: Route, next: NavigationGuardNext) => {
        await axios.get<CourseResponse>(`/wp-json/moc/course/${to.params.courceId}/detail`)
        .then((res) => {
          const course: CourseResponseCourses = res.data.courses[0];
          if (
            (
              isReservedStatus(course) === -1 ||
              isReservedStatus(course) === 1
            ) &&
            isVisibilityOrderBtn(course)
          ) {
            next();
          } else {
            next({
              path: `/course/${to.params.courceId}/` , replace: true,
            });
          }
        })
        .catch((err) => {
          next();
        });
      }),
      children: [
        {
          path: 'form',
          name: 'CourseForm',
          component: () => import(/* webpackChunkName: "form" */ '../../views/course/detail/form/index.vue'),
        },
        {
          path: 'form/error',
          name: 'CourseFormError',
          component: () => import(/* webpackChunkName: "FormError" */ '../../views/NotFound.vue'),
          meta: { title: 'Error 講座' },
        },
        {
          path: 'form/complete',
          name: 'CourseComplete',
          component: () => import(/* webpackChunkName: "complete" */ '../../views/course/detail/form/Complete.vue'),
        },
      ],
    },
  ],
};

export default courseForm;
