import Vue from 'vue';
import VueRouter, { Route, RouteConfig, NavigationGuardNext } from 'vue-router';
import Home from '../views/Home.vue';
import auth from '@/store/auth';
import store from '@/store';
import axios, {AxiosPromise, AxiosResponse, AxiosError} from 'axios';
import { GetLoginResponse, ErrorResponse } from '../../types/typescript-axios/api';
import {AccountEdit, AccountCancel, AccountMail, AccountCourse, AccountReset} from './account/';
import {LoginIndex, LoginForgetPassword, LoginForgetPasswordEdit} from './login/';
import {Signup} from './signup/';
import {CourseIndex, CourseForm, CourseWaiting} from './course/';
import VueGtag from 'vue-gtag';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { title: process.env.VUE_APP_TITLE },
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "defaultLayouts" */ '../layouts/DefaultLayouts.vue'),
    children: [
      {
        path: '/about',
        name: 'About',
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
        meta: { title: 'ワーカーズフォーラムMOCとは？', desc: 'ビジネスに役立つ知識・情報収集の場として、また、より充実したプライベートの時間を過ごすために、様々な講座を通して皆様と共に創り上げる会員制のフォーラムです。' },
      },
      {
        path: '/flow',
        name: 'Flow',
        component: () => import(/* webpackChunkName: "flow" */ '../views/about/Flow.vue'),
        meta: { title: '入会から受講までの流れ', desc: 'ワーカーズフォーラムMOCへのご入会から、講座を受講いただくまでの一般的な流れをご紹介します。' },
      },
      {
        path: '/voice',
        name: 'Voice',
        component: () => import(/* webpackChunkName: "voice" */ '../views/about/Voice.vue'),
        meta: { desc: '講座にご参加いただいた会員様の声の一部をご紹介します。' },
      },
      {
        path: '/faq',
        name: 'Faq',
        component: () => import(/* webpackChunkName: "faq" */ '../views/Faq.vue'),
        meta: { desc: 'サービス内容に関する、よくある質問とその回答を掲載しています。' },
        beforeEnter: (async (to: Route, from: Route, next: NavigationGuardNext) => {
          if (typeof to.query.category === 'undefined') {
            await axios.get<AxiosResponse>('/wp-json/wp/v2/faq_cat')
            .then((res) => {
              const categories: any = res.data;
              if (typeof categories !== 'undefined') {
                const query = to.query;
                query.category = categories[0].slug;
                next({
                  path: '/faq/', query ,
                });
              } else {
                next();
              }
            })
            .catch((err) => {
              next();
            });
          } else {
            next();
          }
        }),
      },
      {
        path: '/info',
        name: 'Info',
        component: () => import(/* webpackChunkName: "info" */ '../views/Info.vue'),
        meta: { title: 'お知らせ・キャンペーン', desc: 'MOC事務局からのお知らせやキャンペーンを掲載しています。お得なクーポン情報もこちらでご覧いただけます。' },
        beforeEnter: ((to: Route, from: Route, next: NavigationGuardNext) => {
          if (typeof to.query.p === 'undefined') {
            const query = to.query;
            query.p = '1';
            next({
              path: '/info/', query,
            });
          } else {
            next();
          }
        }),
      },
      {
        path: '/info/:slug',
        name: 'NewsCoupon',
        component: () => import(/* webpackChunkName: "newsCoupon" */ '../views/info/NewsCoupon.vue'),
      },
      {
        path: '/kiyaku',
        name: 'Kiyaku',
        component: () => import(/* webpackChunkName: "kiyaku" */ '../views/Kiyaku.vue'),
        meta: { title: 'サービスご利用規約' },
      },
      {
        path: '/course',
        name: 'Course',
        component: () => import(/* webpackChunkName: "course" */ '../views/Course.vue'),
        meta: { desc: '最新の講座情報を掲載しています。' },
      },
      {
        path: '/sitemap',
        name: 'Sitemap',
        component: () => import(/* webpackChunkName: "sitemap" */ '../views/Sitemap.vue'),
        meta: { title: 'サイトマップ' },
      },
      {
        path: '/notice',
        name: 'Notice',
        component: () => import(/* webpackChunkName: "notice" */ '../views/Notice.vue'),
        meta: { title: 'サイトご利用上の注意' },
      },
      {
        path: '/notice/suisho',
        name: 'Suisho',
        component: () => import(/* webpackChunkName: "suisho" */ '../views/notice/Suisho.vue'),
        meta: { title: '推奨環境' },
      },
    ],
  },
  CourseIndex,
  CourseForm,
  CourseWaiting,
  Signup,
  LoginIndex,
  LoginForgetPassword,
  LoginForgetPasswordEdit,
  AccountReset,
  AccountEdit,
  AccountCancel,
  AccountMail,
  AccountCourse,
  {
    path: '/logout',
    name: 'Logout',
    component: () => import(/* webpackChunkName: "login" */ '../views/Logout.vue'),
    meta: { title: 'ログアウト' },
    children: [
      {
        path: '',
        name: 'LogoutIndex',
        component: () => import(/* webpackChunkName: "notFound" */ '../views/logout/Index.vue'),
      },
    ],
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "simpleLayouts" */ '../layouts/SimpleLayouts.vue'),
    children: [
      {
        path: '/maintenance_vue',
        name: 'Maintenance',
        component: () => import(/* webpackChunkName: "maintenance" */ '../views/Maintenance.vue'),
      },
    ],
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "defaultLayouts" */ '../layouts/DefaultLayouts.vue'),
    children: [
      {
        path: '*',
        name: 'NotFound',
        component: () => import(/* webpackChunkName: "notFound" */ '../views/NotFound.vue'),
        meta: { title: '404 Error' },
        beforeEnter: ((to: Route, from: Route, next: NavigationGuardNext) => {
          store.commit('window/setNotFound', 404);
          next();
        }),
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return {selector: to.hash};
    }
    return {x: 0, y: 0};
  },
});

router.beforeEach(async (to: Route, from: Route, next: NavigationGuardNext) => {
  // console.log(process.env.NODE_ENV)
  // if (process.env.NODE_ENV !== 'development') {
  //   next();
  // }
  const trailingSlash = /\/$/;
  if (
    auth.state.isLogin === -1 ||
    auth.state.isLogin === 1 ||
    to.matched.some((record) => record.meta.requiresAuth) ||
    to.matched.some((record) => record.meta.requiresNoAuth)
  ) {
    await store.dispatch('auth/loginStatus')
      .then((res: AxiosResponse<GetLoginResponse>) => {
        if (res.data.success) {
          store.commit('auth/login');
          store.commit('auth/setToken', res.data.token);
        } else {
          store.commit('auth/logout');
        }
      });
  }
  if (to.matched.some((record) => record.meta.requiresAuth) && !auth.state.isLogin ) {
    next({ path: '/login/', query: { redirect: to.fullPath }, replace: true});
  } else if (to.matched.some((record) => record.meta.requiresNoAuth) && auth.state.isLogin ) {
    next();
  } else if (!trailingSlash.test(to.path)) {
    next(to.path + '/');
  } else {
    next();
  }
});

router.afterEach((to: Route, from: Route) => {
  if (to.meta?.title) {
    if (to.path === '/') {
      store.commit('window/setTitle', to.meta?.title);
    } else {
      store.commit('window/setTitle', to.meta?.title + ' ' + process.env.VUE_APP_TITLE_SUFFIX);
    }
  }
  document.title = store.getters['window/isTitle'];
  if (to.meta?.desc) {
    store.commit('window/setDesc', to.meta?.desc);
  } else {
    store.commit('window/setDesc', process.env.VUE_APP_META_DESC);
  }
  window.gtag('config', process.env.VUE_APP_GA_ID, {
    page_path: to.fullPath,
    page_title: document.title,
    send_page_view: true,
  });
});

export default router;
