











import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
})
export default class GlobalNavi extends Vue {
  @Prop({ default: false })
  private navBtnShow!: boolean;
  get isLogin() {
    return this.$store.getters['auth/isLogin'];
  }
  get faqUrl(): string {
    return process.env.VUE_APP_PATH_FAQ;
  }
  get loginUrl(): string {
    return process.env.VUE_APP_PATH_LOGIN;
  }
  get signupUrl(): string {
    return process.env.VUE_APP_PATH_SIGNUP;
  }
}
