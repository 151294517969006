




















import { Component, Vue, Emit, Prop, Watch } from 'vue-property-decorator';
import { WPPost, WPCategories } from '../../../../types/typescript-axios/api';
import LabelNew from '@/components/contents/label/LabelNew.vue';
import { data } from 'dom7';

@Component({
  components: {
    LabelNew,
  },
})
export default class InfoListTag extends Vue {
  @Prop({default: []})
  private contents!: WPPost[];
  @Prop({default: []})
  private categories!: WPCategories[];
  @Emit()
  private tagName(id: number): string | undefined {
    let result = [];
    result = this.categories.filter((value: any) => {
      if (value.id === id) {
        return true;
      }
    });
    return (result.length > 0) ? result[0].name : '';
  }
  @Emit()
  private dateFilter(date: string) {
    const dateArr: RegExpMatchArray | null = date.match(/^(\d+)-(\d+)-(\d+)/);
    let result: string = '';
    if (dateArr) {
      result = `${dateArr[1]}年${dateArr[2]}月${dateArr[3]}日`;
    }
    return result;
  }
  @Emit()
  private brReplace(item: string) {
    let result = '';
    result = item.replace( /\n|\r\n|\r/g, '<br>' );
    return result;
  }
  @Emit()
  private replayDate(date: string) {
    const dateArr: RegExpMatchArray | null = date.match(/^(\d+)-(\d+)-(\d+)/);
    if (dateArr) {
      const newDate: Date = new Date(Number(dateArr[1]), Number(dateArr[2]) - 1, Number(dateArr[3]));
      return newDate;
    } else {
      return null;
    }
  }
}
