








import { Component, Vue, Watch } from 'vue-property-decorator';

@Component({
})
export default class App extends Vue {
  // public createTitleDesc(routeInstance: any) {
  //   console.log('aaa')
  //   if (routeInstance.meta.title) {
  //     const setTitle = routeInstance.meta.title + ' ' + process.env.VUE_APP_TITLE_SUFFIX;
  //     document.title = '【TEST】' + setTitle;
  //   } else if (routeInstance.path === '/') {
  //     document.title = '【TEST】' + process.env.VUE_APP_TITLE;
  //   }

  //   let setDesc: string = process.env.VUE_APP_META_DESC;
  //   if (routeInstance.meta.desc) {
  //     setDesc = routeInstance.meta.desc;
  //   }
  //   const descDom: any = document.querySelector('meta[name="description"]');
  //   if (typeof descDom !== 'undefined') {
  //     descDom.setAttribute('content', setDesc);
  //   }
  // }
  // public mounted() {
  // }
  // @Watch('$route')
  // public changeRoute(routeInstance: any) {
  //   this.createTitleDesc(routeInstance);
  // }
}
