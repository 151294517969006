import Vue from 'vue';
import VueRouter, { Route, RouteConfig, NavigationGuardNext } from 'vue-router';
import courseForm from './form';
import courseWaiting from './waiting';

Vue.use(VueRouter);

export const CourseIndex = {
  path: '/',
  component: () => import(/* webpackChunkName: "defaultLayouts" */ '../../layouts/DefaultLayouts.vue'),
  children: [
    {
      path: '/course/:courceId',
      component: () => import(/* webpackChunkName: "CourseDetail" */ '../../views/course/Detail.vue'),
      children: [
        {
          path: '',
          name: 'CourseDetailIndex',
          component: () => import(/* webpackChunkName: "detailIndex" */ '../../views/course/detail/Index.vue'),
        },
      ],
    },
  ],
};

export const CourseForm = courseForm;
export const CourseWaiting = courseWaiting;
export default {
  CourseIndex,
  CourseForm,
  CourseWaiting,
};
