import Vue from 'vue';
import VueRouter, { Route, RouteConfig, NavigationGuardNext } from 'vue-router';

Vue.use(VueRouter);

export const Signup = {
  path: '/',
  component: () => import(/* webpackChunkName: "simpleLayouts" */ '../../layouts/SimpleLayouts.vue'),
  children: [
    {
      path: '/signup',
      component: () => import(/* webpackChunkName: "Signup" */ '../../views/Signup.vue'),
      meta: { requiresNoAuth: true },
      children: [
        {
          path: '',
          name: 'SignupIndex',
          component: () => import(/* webpackChunkName: "SignupIndex" */ '../../views/signup/Signup.vue'),
          meta: { title: '新規会員登録' },
        },
        {
          path: 'mailmagazine',
          component: () => import(/* webpackChunkName: "SignupMailMagazineTerm" */ '../../views/signup/MailMagazineTerm.vue'),
          meta: { title: 'ご利用規約 メルマガ会員登録' },
        },
        {
          path: 'form',
          name: 'SignupForm',
          component: () => import(/* webpackChunkName: "SignupForm" */ '../../views/signup/form/Form.vue'),
          meta: { title: '入力 新規会員登録' },
          beforeEnter: ((to: Route, from: Route, next: NavigationGuardNext) => {
            if (
              from.path === '/signup/' ||
              from.path === '/signup/form/confirm/' ||
              from.path === '/signup/form/error/'
            ) {
              next();
            } else {
              next({ path: '/signup/' });
            }
          }),
        },
        {
          path: 'form/confirm',
          name: 'SignupConfirm',
          component: () => import(/* webpackChunkName: "SignupFormConfirm" */ '../../views/signup/form/Confirm.vue'),
          meta: { title: '確認 新規会員登録' },
          beforeEnter: ((to: Route, from: Route, next: NavigationGuardNext) => {
            if (from.path !== '/signup/form/' && from.path !== '/signup/form/error/') {
              next({ path: '/signup/' });
            } else {
              next();
            }
          }),
        },
        {
          path: 'form/complete',
          name: 'SignupComplete',
          component: () => import(/* webpackChunkName: "SignupComplete" */ '../../views/signup/form/Complete.vue'),
          meta: { title: '完了 新規会員登録' },
          beforeEnter: ((to: Route, from: Route, next: NavigationGuardNext) => {
            if (from.path !== '/signup/form/confirm/') {
              next({ path: '/signup/' });
            } else {
              next();
            }
          }),
        },
        {
          path: 'form/error',
          name: 'error',
          component: () => import(/* webpackChunkName: "SignupError" */ '../../views/signup/form/Error.vue'),
          meta: { title: 'Error 新規会員登録' },
          beforeEnter: ((to: Route, from: Route, next: NavigationGuardNext) => {
            if (from.path !== '/signup/form/confirm/') {
              next({ path: '/signup/' });
            } else {
              next();
            }
          }),
        },
      ],
    },
  ],
};
