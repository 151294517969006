










import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
})
export default class MypageNavi extends Vue {
  @Prop({ default: false })
  private myPageBtnShow!: boolean;
  public async logout() {
    await this.$store.dispatch('auth/logoutAuth');
  }
  get accountCourseUrl(): string {
    return process.env.VUE_APP_PATH_ACCOUNT_COURSE;
  }
  get accountEditAccountUrl(): string {
    return process.env.VUE_APP_PATH_ACCOUNT_EDIT_ACCOUNT;
  }
  get accountEditAccountMailUrl(): string {
    return process.env.VUE_APP_PATH_ACCOUNT_EDIT_ACCOUNT_MAIL;
  }
  get accountCancelUrl(): string {
    return process.env.VUE_APP_PATH_ACCOUNT_CANCEL;
  }
}
