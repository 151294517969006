import { Commit } from 'vuex';
import axios, {AxiosResponse, AxiosError} from 'axios';

export const state = {
  data: null,
};

export const getters = {
  data: (state: any) => {
    return state.data;
  },
};

export const mutations = {
  setData: (state: any, val: string|null) => {
    state.data = val;
  },
};

export const actions = {
  async getData({ commit }: { commit: Commit }) {
    const nonce = await axios.get(`/wp-json/moc/nonce`);
    commit(`setData`, nonce.data);
    return {nonce_client: state.data};
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
