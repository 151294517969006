




























import { Component, Vue } from 'vue-property-decorator';
import SignupNavi from '../contents/footer/SignupNavi.vue';
import AccountNavi from '../contents/footer/AccountNavi.vue';
import FooterContact from '../contents/footer/FooterContact.vue';
import FooterFaq from '../contents/footer/FooterFaq.vue';
import FooterBottom from '../contents/footer/FooterBottom.vue';
import FooterLink from '../contents/footer/FooterLink.vue';

@Component({
  components: {
    SignupNavi,
    AccountNavi,
    FooterContact,
    FooterFaq,
    FooterBottom,
    FooterLink,
  },
})
export default class Footer extends Vue {
  private pageTopBool: boolean = false;
  get isLogin() {
    return this.$store.getters['auth/isLogin'];
  }
  // private pageTopShow() {
  //   if(window.pageYOffset >= 560) {
  //     this.pageTopBool = true
  //   } else {
  //     this.pageTopBool = false
  //   }
  // }
  private pageTop(): void {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
}
