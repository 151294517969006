import { Commit, Dispatch } from 'vuex';
import axios, {AxiosResponse, AxiosError} from 'axios';
import auth from '@/store/auth';
import { load } from 'recaptcha-v3';
import {
  AccountRequest,
  PostLoginResponse,
  PutAccountUpdateRequest,
  AccountDelete,
  PasswordReminder,
  SecretQuestAns,
  PasswordEdit,
} from '../../types/typescript-axios/api';

export const state = {
  editStep: null,
};

export const getters = {
  isEditStep: (state: any) => state.editStep,
};

export const mutations = {
  setEditStep: (state: any, val: number|null) => {
    state.editStep = val;
  },
};

export const actions = {
  /**
   * 新規会員登録
   */
  async signupAuth(
    { commit, dispatch }: { commit: Commit, dispatch: Dispatch },
    param: AccountRequest,
  ) {
    const $param: AccountRequest = param;
    const recaptcha = await load(process.env.VUE_APP_RECAPTCHA_KEY);
    const token = await recaptcha.execute('homepage');
    $param.recaptchaToken = token;
    const nonce = await dispatch('nonce/getData', null , {root: true});
    const query = new URLSearchParams(nonce);
    const res = await new Promise((resolve, reject) => {
      axios.post<AccountRequest, AxiosResponse<PostLoginResponse>>(
        '/wp-json/moc/account?' + query.toString(),
        $param,
        { withCredentials: true },
      )
      .then( (response) => {
        commit('auth/login', 'payload', {root: true});
        resolve(response);
        // commit('setLogin', 'response.data.token');
      })
      .catch( (e: AxiosError) => {
        console.log(e);
        reject(e);
      });
    });
    return res;
  },
  /**
   * 会員情報更新
   */
  async updateMember(
    { commit, dispatch }: { commit: Commit, dispatch: Dispatch },
    param: PutAccountUpdateRequest,
  ) {
    const $param: PutAccountUpdateRequest = param;
    const recaptcha = await load(process.env.VUE_APP_RECAPTCHA_KEY);
    const token = await recaptcha.execute('homepage');
    $param.recaptchaToken = token;
    $param.token = auth.state.token;
    const nonce = await dispatch('nonce/getData', null , {root: true});
    const query = new URLSearchParams(nonce);
    const res = await new Promise((resolve, reject) => {
      axios.put<PutAccountUpdateRequest, AxiosResponse<PostLoginResponse>>(
        '/wp-json/moc/account?' + query.toString(),
        $param,
        { withCredentials: true },
      )
      .then( (response) => {
        resolve(response);
      })
      .catch( (e: AxiosError) => {
        reject(e);
      });
    });
    return res;
  },
  /**
   * 退会処理
   */
  async cancelTheMember(
    { commit, dispatch }: { commit: Commit, dispatch: Dispatch },
    param: AccountDelete,
  ) {
    const $param: AccountDelete = param;
    const recaptcha = await load(process.env.VUE_APP_RECAPTCHA_KEY);
    const token = await recaptcha.execute('homepage');
    $param.recaptchaToken = token;
    $param.token = auth.state.token;
    const nonce = await dispatch('nonce/getData', null , {root: true});
    const query = new URLSearchParams(nonce);
    const res = await new Promise((resolve, reject) => {
      axios.delete<AccountDelete, AxiosResponse<PostLoginResponse>>(
        '/wp-json/moc/account?' + query.toString(),
        {data: param},
      )
      .then( (response) => {
        commit('auth/logout', null, {root: true});
        resolve(response);
      })
      .catch( (e: AxiosError) => {
        console.log(e);
        reject(e);
      });
    });
    return res;
  },
  /**
   * パスワード再設定メール送信
   */
  async authForgetPasswordMail(
    { commit, dispatch }: { commit: Commit, dispatch: Dispatch },
    param: PasswordReminder,
  ) {
    const $param: PasswordReminder = param;
    const recaptcha = await load(process.env.VUE_APP_RECAPTCHA_KEY);
    const token = await recaptcha.execute('homepage');
    $param.recaptchaToken = token;
    const nonce = await dispatch('nonce/getData', null , {root: true});
    const query = new URLSearchParams(nonce);
    const res = await new Promise((resolve, reject) => {
      axios.post<PasswordReminder, AxiosResponse>(
        '/wp-json/moc/account/password/mail?' + query.toString(),
        param,
      )
      .then( (response) => {
        resolve(response);
      })
      .catch( (e: AxiosError) => {
        console.log(e);
        reject(e);
      });
    });
    return res;
  },
  /**
   * 秘密の質問
   */
  async question(
    { commit, dispatch }: { commit: Commit, dispatch: Dispatch },
    data: {id: any, params: SecretQuestAns},
  ) {
    const $param: SecretQuestAns = data.params;
    const recaptcha = await load(process.env.VUE_APP_RECAPTCHA_KEY);
    const token = await recaptcha.execute('homepage');
    $param.recaptchaToken = token;
    const nonce = await dispatch('nonce/getData', null , {root: true});
    const query = new URLSearchParams(nonce);
    const res = await new Promise((resolve, reject) => {
      axios.post<SecretQuestAns, AxiosResponse>(
        `/wp-json/moc/account/password/${data.id}/question?` + query.toString(),
        data.params,
      )
      .then( (response) => {
        resolve(response);
      })
      .catch( (e: AxiosError) => {
        console.log(e);
        reject(e);
      });
    });
    return res;
  },
  /**
   * パスワード再設定
   */
  async resetPassword(
    { commit, dispatch }: { commit: Commit, dispatch: Dispatch },
    data: {key: any, params: PasswordEdit},
  ) {
    const $param: PasswordEdit = data.params;
    const recaptcha = await load(process.env.VUE_APP_RECAPTCHA_KEY);
    const token = await recaptcha.execute('homepage');
    $param.recaptchaToken = token;
    const nonce = await dispatch('nonce/getData', null , {root: true});
    const query = new URLSearchParams(nonce);
    const res = await new Promise((resolve, reject) => {
      axios.put<PasswordEdit, AxiosResponse>(
        `/wp-json/moc/account/password/${data.key}/edit?` + query.toString(),
        data.params,
      )
      .then( (response) => {
        resolve(response);
      })
      .catch( (e: AxiosError) => {
        console.log(e);
        reject(e);
      });
    });
    return res;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
