






















































import { Component, Vue } from 'vue-property-decorator';

@Component({
})
export default class AccountNavi extends Vue {
  get aboutUrl(): string {
    return process.env.VUE_APP_PATH_ABOUT;
  }
  get aboutFlowUrl(): string {
    return process.env.VUE_APP_PATH_ABOUT_FLOW;
  }
  get aboutVoiceUrl(): string {
    return process.env.VUE_APP_PATH_ABOUT_VOICE;
  }
  get faqUrl(): string {
    return process.env.VUE_APP_PATH_FAQ;
  }
  get infoUrl(): string {
    return process.env.VUE_APP_PATH_INFO;
  }
  get accountCourseUrl(): string {
    return process.env.VUE_APP_PATH_ACCOUNT_COURSE;
  }
  get accountEditAccountUrl(): string {
    return process.env.VUE_APP_PATH_ACCOUNT_EDIT_ACCOUNT;
  }
  get accountEditAccountMailUrl(): string {
    return process.env.VUE_APP_PATH_ACCOUNT_EDIT_ACCOUNT_MAIL;
  }
  get accountCancelUrl(): string {
    return process.env.VUE_APP_PATH_ACCOUNT_CANCEL;
  }
  get courseUrl(): string {
    return process.env.VUE_APP_PATH_COURSE;
  }
}
