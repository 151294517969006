import Vue from 'vue';
import VueRouter, { Route, RouteConfig, NavigationGuardNext } from 'vue-router';
import store from '@/store';

Vue.use(VueRouter);

export const course = {
  path: '/',
  component: () => import(/* webpackChunkName: "simpleLayouts" */ '../../layouts/SimpleLayouts.vue'),
  children: [
    {
      path: '/account/course',
      component: () => import(/* webpackChunkName: "account" */ '../../views/account/Course.vue'),
      meta: { requiresAuth: true },
      children: [
        {
          path: '',
          name: 'AccountCourse',
          component: () => import(/* webpackChunkName: "accountCourse" */ '../../views/account/course/Course.vue'),
          meta: { title: '申込み講座履歴（確認・キャンセル）' },
          beforeEnter: ((to: Route, from: Route, next: NavigationGuardNext) => {
            if (typeof to.query.p === 'undefined') {
              const query = to.query;
              query.p = '1';
              next({ path: '/account/course/', query , replace: true });
            } else {
              next();
            }
          }),
        },
        {
          path: 'detail/:courceId/cancel',
          name: 'AccountCourseDetail',
          component: () => import(/* webpackChunkName: "AccountCourseDetail" */ '../../views/account/course/detail/Cancel.vue'),
          children: [
            {
              path: 'confirm',
              name: 'AccountConfirm',
              component: () => import(/* webpackChunkName: "accountConfirm" */ '../../views/account/course/detail/cancel/Confirm.vue'),
            },
            {
              path: 'error',
              name: 'AccountConfirmError',
              component: () => import(/* webpackChunkName: "AccountConfirmError" */ '../../views/account/course/detail/cancel/Error.vue'),
              meta: { title: 'Error 申込み講座のキャンセル' },
            },
            {
              path: 'complete',
              name: 'AccountComplete',
              component: () => import(/* webpackChunkName: "accountComplete" */ '../../views/account/course/detail/cancel/Complete.vue'),
            },
          ],
        },
      ],
    },
  ],
};

export default course;
