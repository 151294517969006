




































import { Component, Vue } from 'vue-property-decorator';

@Component({
})
export default class SignupNavi extends Vue {
  get aboutUrl(): string {
    return process.env.VUE_APP_PATH_ABOUT;
  }
  get aboutFlowUrl(): string {
    return process.env.VUE_APP_PATH_ABOUT_FLOW;
  }
  get aboutVoiceUrl(): string {
    return process.env.VUE_APP_PATH_ABOUT_VOICE;
  }
  get signupUrl(): string {
    return process.env.VUE_APP_PATH_SIGNUP;
  }
}
